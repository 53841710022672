import React from 'react'
import ThirtytwoCardA from "../../../../assets/Sample/32-Cards-A.png"
import ThirtytwoCardB from "../../../../assets/Sample/32-Cards-B.png"

export default function ThirtyTwoCards() {
  return (
    <div className="tab-content">
  <div className="tab-pane active" id="all-casino">
    <div className="casino-list">
      <div className="casino-list-item">
        <a href="/casino/card32">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                `url(${ThirtytwoCardA}), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")`,
            }}
          />
        </a>
      </div>
      <div className="casino-list-item">
        <a href="/casino/card32eu">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                `url(${ThirtytwoCardB}), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")`,
            }}
          />
        </a>
      </div>
    </div>
  </div>
</div>

  )
}

