import React from 'react'
import AndharBhar from "../../../../assets/Sample/Andar-Bahar.png"
import AndharBharTwo from "../../../../assets/Sample/Andar-Bahar-2.png"

export default function AndarBahar() {
  return (
<div className="tab-content">
  <div className="tab-pane active" id="all-casino">
    <div className="casino-list">
      {/* <div className="casino-list-item">
        <a href="/casino/ab4">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/ab4.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
            }}
          />
        </a>
      </div> */}
      {/* <div className="casino-list-item">
        <a href="/casino/ab3">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/ab3.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
            }}
          />
        </a>
      </div> */}
      <div className="casino-list-item">
        <a href="/casino/ab20">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                `url(${AndharBhar}), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")`,
            }}
          />
        </a>
      </div>
      <div className="casino-list-item">
        <a href="/casino/abj">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                `url(${AndharBharTwo}), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")`,
            }}
          />
        </a>
      </div>
    </div>
  </div>
</div>
  )
}
