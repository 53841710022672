import React from "react";
import { getExposureTableAPI } from "../service/AuthService";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
export default function ExposureTable({ onClose }) {
  const [exposureTable, setExposureTable] = React.useState([]);
  const getExposureTable = async () => {
    var { response, code } = await getExposureTableAPI();
    if (code === 200) {
      setExposureTable(response);
      toast.success("Exposure Table Updated");
    }
  };
  useEffect(() => {
    getExposureTable();
  }, []);
  return (
    <div
      id="myMarketModal___BV_modal_outer_"
      style={{ position: "absolute", zIndex: 1040 }}
    >
      <div
        id="myMarketModal"
        role="dialog"
        aria-labelledby="myMarketModal___BV_modal_title_"
        aria-describedby="myMarketModal___BV_modal_body_"
        className="modal fade show"
        aria-modal="true"
        style={{ display: "block", color: "black" }}
      >
        <div className="modal-dialog modal-lg">
          <span tabIndex={0} />
          <div
            role="document"
            id="myMarketModal___BV_modal_content_"
            tabIndex={-1}
            className="modal-content"
          >
            <header
              id="myMarketModal___BV_modal_header_"
              className="modal-header"
            >
              <h4 className="modal-title theme1font">
                My Market
                <a onClick={() => getExposureTable()}>
                  <i className="fas fa-sync-alt ml-1" />
                </a>
              </h4>
              <button
                onClick={() => onClose(false)}
                type="button"
                data-dismiss="modal"
                className="close"
              >
                <i aria-hidden="true" className="fa fa-times" />
              </button>
            </header>
            <div id="myMarketModal___BV_modal_body_" className="modal-body">
              <table className="table-responsive ">
                <thead>
                  <tr className="theme1font">
                    <th>Event Type</th>
                    <th>Event Name</th>
                    <th>Match Name</th>
                    <th>Trade</th>
                  </tr>
                </thead>
                <tbody>
                  {exposureTable?.length == 0 ? (
                    <tr className="norecordsfound">
                      <td colSpan={4} className="text-center">
                        No real-time records found
                      </td>
                    </tr>
                  ) : (
                    exposureTable?.map((item, index) => (
                      <tr
                        onClick={() => {
                          const route =
                            item.eventType === "CRICKET" ||
                            item.eventType === "TENNIS" ||
                            item.eventType === "SOCCER"
                              ? `/game/${item.eventType?.toLowerCase()}/${
                                  item?.eventId
                                }`
                              : `/${item.eventType?.toLowerCase()}/${
                                  item?.eventId
                                }`;
                          window.location.href = route;
                        }}
                        key={index}
                      >
                        <td>{item.eventType}</td>
                        <td>{item.eventName}</td>
                        <td>{item.marketName}</td>
                        <td>{item.trade}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <span tabIndex={0} />
        </div>
      </div>
      <div id="myMarketModal___BV_modal_backdrop_" className="modal-backdrop" />
    </div>
  );
}
