import React from 'react'
import FiveCricket from "../../../../assets/Sample/Five-Cricket.png"
import CasinoMeter from "../../../../assets/Sample/Casino-Meter.png"
import SuperOver from "../../../../assets/Sample/Super-Over.png"
import Cricket2020 from "../../../../assets/Sample/Cricket-20-20.png"




export default function Sports() {
  return (
<div className="tab-content">
  <div className="tab-pane active" id="all-casino">
    <div className="casino-list">
      {/* <div className="casino-list-item">
        <a href="/casino/superover2">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/superover2.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
            }}
          />
        </a>
      </div> */}
      {/* <div className="casino-list-item">
        <a href="/casino/goal">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/goal.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
            }}
          />
        </a>
      </div> */}
      {/* <div className="casino-list-item">
        <a href="/casino/superover3">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/superover3.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
            }}
          />
        </a>
      </div> */}
      {/* <div className="casino-list-item">
        <a href="/casino/cmeter1">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/cmeter1.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
            }}
          />
        </a>
      </div> */}
      <div className="casino-list-item">
        <a href="/casino/cricketv3">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                `url(${FiveCricket}), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")`,
            }}
          />
        </a>
      </div>
      {/* <div className="casino-list-item">
        <a href="/casino/cmatch20">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                `url(${Cricket2020}), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")`,
            }}
          />
        </a>
      </div> */}
      <div className="casino-list-item">
        <a href="/casino/cmeter">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                `url(${CasinoMeter}), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")`,
            }}
          />
        </a>
      </div>
      <div className="casino-list-item">
        <a href="/casino/superover">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                `url(${SuperOver}), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")`,
            }}
          />
        </a>
      </div>
    </div>
  </div>
</div>

  )
}
