import React from 'react'
import { useParams } from 'react-router-dom';

export default function SportIframe() {
    const { id } = useParams();
  return (
    <iframe
    style={{ width: "100%", height: "100vh" }}
    src={`https://video.proexch.in/tv/static?chid=${id}`}
  />
  )
}
