import React from "react";
import Teen20 from "./resultModals/Teen20";
import TeenpattiOpen from "./resultModals/TeenpattiOpen";
import Teen9 from "./resultModals/Teen9";
import Teenpatti1Day from "./resultModals/Teenpatti1Day";
import SuperOver from "./resultModals/SuperOver";
import Race20 from "./resultModals/Race20";
import AB2 from "./resultModals/AndarBahar2";
import DragonTiger2020 from "./resultModals/2020DragonTiger";
import Baccarat from "./resultModals/Baccarat";
import Baccarat2 from "./resultModals/Baccarat2";
import Lucky7B from "./resultModals/Lucky7B";
import CasinoWar from "./resultModals/CasinoWar";
import Cmeter from "./resultModals/Cmeter";
import Card32 from "./resultModals/Card32";
import Card32B from "./resultModals/Card32B";
import Queen from "./resultModals/Queen";
import Dt1day from "./resultModals/Dt1day";
import Poker2020 from "./resultModals/Poker2020";
import InstantWorli from "./resultModals/InstantWorli";
import AAA from "./resultModals/AAA";
import BTable from "./resultModals/BTable";
import AndarBahar from "./resultModals/AndarBahar";
import Cricket20 from "./resultModals/Cricket20";
import Lucky7Aa from "./resultModals/Lucky7A";
import moment from "moment/moment";
import FiveCricket from "./resultModals/FiveCricket";

export default function GenericIndividualResult({
  data,
  shortName,
  onClose,
  GAME_ID,
}) {
  console.log("shortName", shortName);
  console.log("GAME_ID", GAME_ID);
  const renderResult = () => {
   switch (shortName || GAME_ID) {
  case "teen":
  case "teenpatti1day":
    return <Teenpatti1Day data={data} />;

  case "teen_20":
    return <Teen20 data={data} />;


  case "teen8":
    return <TeenpattiOpen data={data} />;

  case "teen_9":
  case "teenpattitest":
    return <Teen9 data={data} />;

  case "superover":
  case "super-over":
    return <SuperOver data={data} />;

  case "race20":
    return <Race20 data={data} />;

  case "abj":
  case "ab2":
    return <AB2 data={data} />;
 
  case "dt20":
  case "dragon_tiger_20":
  case "dragon_tiger_20_2":  
    return <DragonTiger2020 data={data} />;

  case "baccarat":
    return <Baccarat data={data} />;

  case "baccarat2":
  case "baccarat baccarat2game":
    return <Baccarat2 data={data} />;

  case "casino-war":
  case "casino_war":
    return <CasinoWar data={data} />;

 
  case "cmeter":
  case "casino_meter":  
    return <Cmeter data={data} />;

  case "card32eu":
  case "cards32a cards32b":
    return <Card32B data={data} />;

  case "card_32":
  case "cards32a":
        return <Card32 data={data} />;

  case "queen":
  case "casino_queen":
    return <Queen data={data} />;

  case "dragon_tiger_6":
  case "dt1day":
    return <Dt1day data={data} />;

  case "poker_20":
  case "poker20":
    return <Poker2020 data={data} />;

  case "cricket_v3":
  case "fiveCricket":  
    return <FiveCricket data={data} />;  

  case "poker_1_day":
  case "cards32a poker-20 Poker_oneday":
    return <Poker2020 data={data} />;

  case "worli2":
  case "worli":
    return <InstantWorli data={data} />;

  case "bollywood_table":
  case "bollywood":
    return <BTable data={data} />;

  case "aaa":
    return <AAA data={data} />;

  case "ab_20":
  case "ab ab3":
    return <AndarBahar data={data} />;

  case "cricket_match_20":
  case "cricket20":
    return <Cricket20 data={data} />;

  case "lucky7":
  case "lucky7acards":
    return <Lucky7Aa data={data} />;

  case "lucky7eu":
  case "lucky7acards":
    return <Lucky7B data={data} />;

  default:
    return renderDefaultResult();
}

    
  };
  // if (GAME_ID === "LUCKY7") {
  //   return <Lucky7Aa data={data} />;
  // }

  const renderDefaultResult = () => {
    return (
      <div className="modal-body">
        <h1>Hello</h1>
      </div>
    );
  };

  const getModalTitle = () => {
    switch (shortName || GAME_ID) {
      case "teenpatti1day":
      case "teen":
        return "Teen Patti Result";
    
      case "teenpattiopen":
      case "teen_8":
        return "Teen Patti Open Result";
    
      case "teenpattitest":
      case "teen_9":
        return "Teen Patti Test Result";

      case "casino_war":
      case "casino-war":
        return "Casino War";

      case "super-over":
      case "superover":
        return "Super Over";
    
      case "race20":
        return "Race 20";
    
      case "ab2":
      case "abj":
        return "Andar Bahar 2";
    
      case "dt20":
      case "dragon_tiger_20":
        return "Dragon Tiger 20-20";
    
      case "dt1day":
      case "dragon_tiger_6":
        return "Dragon Tiger 1 Day";
    
      case "baccarat":
        return "Baccarat";
    
      case "baccarat2":
      case "baccarat baccarat2game":
        return "Baccarat2";
    
      // case "casino-war":
      // case "casino_war":
      //   return "Casino War";
    
      case "cmeter":
      case "casino_meter":
        return "Casino Meter";
    
      case "cards32a":
      case "card_32":
        return "Card 32";
    
      case "cards32a cards32b":
      case "card32eu":
        return "Card 32 B";
    
      case "queen":
      case "casino_queen":
        return "Casino Queen";
    
      case "aaa":
        return "Amar Akbar Anthony";
    
      case "bollywood":
      case "bollywood_table":
        return "Bollywood Casino";
        
      case "ab_20":
      case "ab ab3":
        return "Andar Bahar";
    
      case "cricket20":
      case "cricket_match_20":
        return "20-20 Cricket";
    
      case "cricket_v3":
      case "fiveCricket":  
        return "Five Cricket";
    
      case "lucky7":
      case "lucky7acards":
        return "Lucky 7 - A";
    
      case "lucky7eu":
      case "lucky7acards":
        return "Lucky 7 - B";
    
      case "poker_1_day":
        return "Poker 1 Day";
    
      case "poker_20":
     case  "poker20":
        return "Poker 20-20";
    
      case "teen_20":
        return "Teen Patti, T20 20";
    
      case "worli":
      case "worli2":
        return "Instant Worli";
    
      default:
        return "Game Result";
    }
    
    
  };
  // if (GAME_ID === "LUCKY7") {
  //   return "Lucky 7 - A";
  // }

  return (
    <div
      role="dialog"
      aria-modal="true"
      className="fade modal show"
      tabIndex={-1}
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title h4">{getModalTitle()}</div>
            <button
              onClick={onClose}
              type="button"
              className="btn-close"
              aria-label="Close"
            />
          </div>
          {renderResult()}
          <div className="row mt-4 justify-content-between">
            <div className="col-md-6">
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  className="form-check-input"
                  id="all"
                  name="filter"
                  defaultValue="all"
                  defaultChecked=""
                />
                All
                <label className="form-check-label" htmlFor="all" />
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  className="form-check-input"
                  id="back"
                  name="filter"
                  defaultValue="all"
                />
                Back
                <label className="form-check-label" htmlFor="back" />
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  className="form-check-input"
                  id="lay"
                  name="filter"
                  defaultValue="all"
                />
                Lay
                <label className="form-check-label" htmlFor="lay" />
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  className="form-check-input"
                  id="delete"
                  name="filter"
                  defaultValue="all"
                />
                Deleted
                <label className="form-check-label" htmlFor="delete" />
              </div>
            </div>
            <div className="col-md-6 text-end">
              <div>
                Total Bets:{" "}
                <span className="me-2">
                  {data?.betResponseDTOList?.length}
                </span>
                Total Amount:
                <span className="me-2 text-danger">
                  -{data?.totalProfitLoss}
                </span>
              </div>
            </div>
          </div>
          <div className="mt-2 table-responsive">
            <table
              role="table"
              className="table table-bordered table-striped"
            >
              <thead>
                <tr role="row">
                  <th colSpan={1} role="columnheader">
                    Nation
                  </th>
                  <th colSpan={1} role="columnheader" className="text-end">
                    Rate
                  </th>
                  <th colSpan={1} role="columnheader" className="text-end">
                    Amount
                  </th>
                  <th colSpan={1} role="columnheader" className="text-end">
                    Win
                  </th>
                  <th colSpan={1} role="columnheader">
                    Date
                  </th>
                  <th colSpan={1} role="columnheader">
                    Ip Address
                  </th>
                  <th colSpan={1} role="columnheader">
                    Browser Details
                  </th>
                  <th colSpan={1} role="columnheader">
                    <div className="text-end">
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          title="Toggle All Rows Selected"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                {data?.betResponseDTOList?.map((item) => (
                  <tr role="row" className={item?.backRate ? "back" : "lay"}>
                    <td role="cell">{item?.runnerName}</td>
                    <td role="cell" className="text-end">
                      {item?.backRate ? item?.backRate : item?.layRate}
                    </td>
                    <td role="cell" className="text-end">
                      {item?.amount}
                    </td>
                    <td role="cell" className="text-end">
                      <span
                        role="cell"
                        className={
                          item?.profitLoss > 0
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        {item?.profitLoss}
                      </span>
                    </td>
                    <td role="cell">
                      <span role="cell">
                        {moment(item?.createdDate + "Z").format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                      </span>
                    </td>
                    <td role="cell">{item?.ip}</td>
                    <td role="cell">
                      <u>{item?.userAgent}</u>
                    </td>
                    <td role="cell">
                      <div className="text-end">
                        <div className="form-check form-check-inline" />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
