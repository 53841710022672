import React from 'react'
import QueeN from "../../../../assets/Sample/Casino-Queen.png"

export default function Queen() {
  return (
<div className="tab-content">
  <div className="tab-pane active" id="all-casino">
    <div className="casino-list">
      <div className="casino-list-item">
        <a href="/casino/queen">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                `url(${QueeN}), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")`,
            }}
          />
        </a>
      </div>
    </div>
  </div>
</div>
  )
}
