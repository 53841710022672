import React from "react";
import { useEffect } from "react";
import { getAccountStatementAPI } from "../../service/UserService";
import { searchSubstringInArrayObjects } from "../../utils/constants";
import { useDispatch } from "react-redux";
import { getBetsFromAccountLogs } from "../../service/AuthService";
import moment from "moment";
import DetailModal from "./DetailModal";
export default function AccountStatementM() {
  const [data, setData] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [currentPageData, setCurrentPageData] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [type, setType] = React.useState("ALL");
  const dispatch = useDispatch();
  const [startDate, setStartDate] = React.useState(
    moment()
      .subtract(7, "days")
      .format("YYYY-MM-DD")
  );
  const [noOfPages, setNoOfPages] = React.useState(0);
  const [endDate, setEndDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [selectedType, setSelectedType] = React.useState("ALL");
  const [logData, setLogData] = React.useState([]);
  const [selectedLogId, setSelectedLogId] = React.useState(null);
  const isMobileView = window.innerWidth < 768;
  const getData = async (id, TYPE = "ALL") => {
    setSelectedLogId(id);
    const { response } = await getBetsFromAccountLogs(
      selectedLogId ? selectedLogId : id
    );
    let response_ = response;
    let betResponseDTOList = response_.betResponseDTOList;
    let betResponseDTOList_ = [];
    betResponseDTOList.forEach((item) => {
      if (item.back_rate && item.event_type == "Casino") {
        item.type = "back";
        item.rate = item.back_rate;
      } else if (item.lay_rate && item.event_type == "Casino") {
        item.type = "lay";
        item.rate = item.lay_rate;
      }
      if (
        item.back_run &&
        (item.event_type == "Cricket" ||
          item.event_type == "Soccer" ||
          item.event_type == "Tennis")
      ) {
        item.type = "back";
        item.rate = item.back_run;
      } else if (
        item.lay_run &&
        (item.event_type == "Cricket" ||
          item.event_type == "Soccer" ||
          item.event_type == "Tennis")
      ) {
        item.type = "lay";
        item.rate = item.lay_run;
      }
      betResponseDTOList_.push(item);
    });
    response_.betResponseDTOList = betResponseDTOList_;

    if (TYPE == "ALL") {
      response_ = response;
    }
    if (TYPE == "BACK") {
      response_.betResponseDTOList = response_.betResponseDTOList.filter(
        (item) => item.type == "back"
      );
    }
    if (TYPE == "LAY") {
      response_.betResponseDTOList = response_.betResponseDTOList.filter(
        (item) => item.type == "lay"
      );
    }

    setLogData(response_);
  };

  const getAccountStatement = async () => {
    let payload = {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      type: type,
    };
    const { response } = await getAccountStatementAPI(payload);
    setData(response);
    setNoOfPages(Math.ceil(response.length / rowsPerPage));
    setCurrentPageData(response.slice(0, rowsPerPage));
   
  };

  const updateCurrentPageData = () => {
    // Update current page data based on pageNumber and rowsPerPage
    const startIndex = pageNumber * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    setCurrentPageData(data.slice(startIndex, endIndex));
  };


  const handlePageChange = (newPage) => {
    setPageNumber(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPageNumber(0); // Reset to the first page
  };

  const search = async (e) => {
    if (e.target.value === "") return getAccountStatement();
    const filteredData = searchSubstringInArrayObjects(
      data,
      ["comment", "debitInfo", "creditInfo", "updatedBalance"],
      e.target.value
    );
    setData(filteredData);
    setNoOfPages(Math.ceil(filteredData.length / rowsPerPage));
    setCurrentPageData(filteredData.slice(0, rowsPerPage));
  };

  useEffect(() => {
    getAccountStatement();
  }, [type, startDate, endDate]);

  useEffect(() => {
    updateCurrentPageData();
  }, [pageNumber, rowsPerPage, data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    getAccountStatement();
  };
  // useEffect(() => {
  //   getAccountStatement();
  // }, [type, startDate, endDate]);
  // useEffect(() => {
  //   setCurrentPageData(
  //     data.slice(
  //       pageNumber * rowsPerPage,
  //       pageNumber * rowsPerPage + rowsPerPage
  //     )
  //   );
  // }, [pageNumber, selectedType]);
  return (
    <div
      style={{
        width: isMobileView ? "100%" : "82%",
      }}
      className=" report-container"
    >
      <div className="card">
        {isModalOpen && (
          <DetailModal
            logData={logData}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        )}
        <div className="card-header">
          <h4 className="mb-0">Account Statement</h4>
        </div>
        <div className="card-body container-fluid container-fluid-5">
          <div className="row row5">
            <div className="col-6">
              <div className="form-group mb-0">
                <input
                  type="date"
                  className="form-control form-control-sm"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group mb-0">
                <input
                  type="date"
                  className="form-control form-control-sm"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row row5 mt-2">
            <div className="col-12">
              <div className="form-group mb-0">
                <select
                  onChange={(e) => setType(e.target.value)}
                  name="reportType"
                  className="custom-select"
                >
                  <option value={"ALL"}>All</option>
                  <option value={"BALANCE_REPORT"}>
                    Deposit/Withdraw Report
                  </option>
                  <option value={"GAME_REPORT"}>Game Report</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row row5 mt-2">
            <div className="col-12">
              <button
                onClick={() => {
                  getAccountStatement();
                }}
                className="btn btn-primary btn-block btn-sm"
              >
                Submit
              </button>
            </div>
          </div>
          <div className="row row5 mt-2">
            <div className="col-6">
              <div
                id="account-statement_length"
                className="dataTables_length"
              >
                <label>
                  Show
                  <select
                onChange={(e) => handleRowsPerPageChange(Number(e.target.value))}
                className="form-control form-control-sm theme1font"
              >
                {[10, 15, 20, 25, 30, 40, 50].map((value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </select>
                  {/* <select
                    onChange={(e) => {
                      setRowsPerPage(e.target.value);
                      setCurrentPageData(
                        data.slice(
                          pageNumber * e.target.value,
                          pageNumber * e.target.value + e.target.value
                        )
                      );
                    }}
                    name="account-statement_length"
                    aria-controls="account-statement"
                    className="form-control form-control-sm theme1font"
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                    <option value={25}>25</option>
                    <option value={30}>30</option>
                    <option value={35}>35</option>
                    <option value={40}>40</option>
                    <option value={45}>45</option>
                    <option value={50}>50</option>
                  </select> */}
                  entries
                </label>
              </div>
            </div>
            <div className="col-6">
              <div
                id="account-statement_filter"
                className="dataTables_filter"
              >
                <label>
                  Search:
                  <input
                    type="search"
                    placeholder="Type to Search"
                    aria-controls="account-statement"
                    className="form-control form-control-sm"
                    onChange={search}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="row row5">
            <div className="col-12">
              <div className="table-responsive">
                <table
                  role="table"
                  aria-busy="false"
                  aria-colcount={6}
                  className="table b-table table-bordered"
                  id="__BVID__116"
                >
                  <thead role="rowgroup" className="">
                    <tr role="row" className="">
                    <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={1}
                        className="text-right"
                      >
                        SR
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={2}
                        className="text-center"
                      >
                        Date
                      </th>
                    
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={3}
                        className="text-right"
                      >
                        Credit
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={4}
                        className="text-right"
                      >
                        Debit
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={5}
                        className="text-right"
                      >
                        Balance
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={6}
                        className="text-center"
                      >
                        Remark
                      </th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    {currentPageData?.map((item, index) => (
                      <tr
                        role="row"
                        tabIndex={0}
                        aria-rowindex={1}
                        className=""
                      >
                         <td
                          aria-colindex={1}
                          role="cell"
                          className="text-right"
                        >
                         {pageNumber * rowsPerPage + index + 1}
                        </td>
                        <td
                          aria-colindex={2}
                          role="cell"
                          className="text-center"
                        >
                          <span>
                            {/* 2023-07-01 00:00 */}
                            {moment(item.createdAt).format(
                              "DD/MM/YYYY hh:mm"
                            )}
                          </span>
                        </td>
                       
                        <td
                          aria-colindex={2}
                          role="cell"
                          className="text-right"
                        >
                          <span lassName="text-success">
                            {item?.creditInfo}
                          </span>
                        </td>
                        <td
                          aria-colindex={3}
                          role="cell"
                          className="text-right"
                        >
                          <span className="text-danger">
                            {item?.debitInfo}
                          </span>
                        </td>

                        <td
                          aria-colindex={5}
                          role="cell"
                          className="text-right"
                        >
                          <span className="text">
                            {item?.updatedBalance?.toFixed(2)}
                          </span>
                        </td>
                        <td
                          aria-colindex={6}
                          role="cell"
                          onClick={() => {
                            if (item.type == "GAME_REPORT") {
                              setSelectedLogId(item.accountLogId);
                              getData(item.accountLogId, "ALL");

                              setIsModalOpen(true);
                            }
                          }}
                          className="text-center"
                        >
                          {item.comment}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* <div className="row row5 mt-2">
            <div className="col-12">
              <ul
                role="menubar"
                aria-disabled="false"
                aria-label="Pagination"
                className="pagination mb-0 b-pagination justify-content-center"
              >
                <li
                  role="presentation"
                  aria-hidden="true"
                  className="page-item disabled"
                >
                  <span
                    role="menuitem"
                    aria-label="Go to first page"
                    aria-disabled="true"
                    className="page-link"
                  >
                    First
                  </span>
                </li>
                <li
                  role="presentation"
                  aria-hidden="true"
                  className="page-item disabled"
                >
                  <span
                    role="menuitem"
                    aria-label="Go to previous page"
                    aria-disabled="true"
                    className="page-link"
                  >
                    Prev
                  </span>
                </li>

                {Array.from(Array(noOfPages).keys()).map((item) => (
                  <li
                    role="presentation"
                    className={`page-item ${
                      pageNumber == item ? "active" : ""
                    }`}
                  >
                    <a
                      role="menuitemradio"
                      aria-label={`Go to page ${item + 1}`}
                      aria-checked="true"
                      aria-posinset={item + 1}
                      aria-setsize={noOfPages}
                      tabIndex={0}
                      target="_self"
                      href="#"
                      className={`page-link ${
                        pageNumber == item ? "active" : ""
                      }`}
                      onClick={() => {
                        setPageNumber(item);
                      }}
                    >
                      {item + 1}
                    </a>{" "}
                  </li>
                ))}

                <li
                  role="presentation"
                  aria-hidden="true"
                  className="page-item disabled"
                >
                  <span
                    role="menuitem"
                    aria-label="Go to next page"
                    aria-disabled="true"
                    className="page-link"
                  >
                    Next
                  </span>
                </li>
                <li
                  role="presentation"
                  aria-hidden="true"
                  className="page-item disabled"
                >
                  <span
                    role="menuitem"
                    aria-label="Go to last page"
                    aria-disabled="true"
                    className="page-link"
                  >
                    Last
                  </span>
                </li>
              </ul>
            </div>
          </div> */}
                {/* <div className="pagination">
            {Array.from(Array(noOfPages).keys()).map((page) => (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={`btn ${pageNumber === page ? "btn-primary" : "btn-light"}`}
              >
                {page + 1}
              </button>
            ))}
          </div> */}
          <div className="row row5 mt-2">
  <div className="col-12">
    <ul
      role="menubar"
      aria-disabled="false"
      aria-label="Pagination"
      className="pagination mb-0 b-pagination justify-content-center"
    >
      {/* First Button */}
      <li
        role="presentation"
        className={`page-item ${pageNumber === 0 ? "disabled" : ""}`}
      >
        <span
          role="menuitem"
          aria-label="Go to first page"
          aria-disabled={pageNumber === 0}
          className="page-link"
          onClick={() => {
            if (pageNumber > 0) setPageNumber(0);
          }}
        >
          First
        </span>
      </li>

      {/* Previous Button */}
      <li
        role="presentation"
        className={`page-item ${pageNumber === 0 ? "disabled" : ""}`}
      >
        <span
          role="menuitem"
          aria-label="Go to previous page"
          aria-disabled={pageNumber === 0}
          className="page-link"
          onClick={() => {
            if (pageNumber > 0) setPageNumber(pageNumber - 1);
          }}
        >
          Prev
        </span>
      </li>

      {/* Page Numbers */}
      {Array.from(Array(noOfPages).keys()).map((item) => (
        <li
          role="presentation"
          className={`page-item ${pageNumber === item ? "active" : ""}`}
        >
          <a
            role="menuitemradio"
            aria-label={`Go to page ${item + 1}`}
            aria-checked={pageNumber === item}
            aria-posinset={item + 1}
            aria-setsize={noOfPages}
            tabIndex={0}
            target="_self"
            href="#"
            className={`page-link ${pageNumber === item ? "active" : ""}`}
            onClick={(e) => {
              e.preventDefault();
              setPageNumber(item);
            }}
          >
            {item + 1}
          </a>
        </li>
      ))}

      {/* Next Button */}
      <li
        role="presentation"
        className={`page-item ${pageNumber === noOfPages - 1 ? "disabled" : ""}`}
      >
        <span
          role="menuitem"
          aria-label="Go to next page"
          aria-disabled={pageNumber === noOfPages - 1}
          className="page-link"
          onClick={() => {
            if (pageNumber < noOfPages - 1) setPageNumber(pageNumber + 1);
          }}
        >
          Next
        </span>
      </li>

      {/* Last Button */}
      <li
        role="presentation"
        className={`page-item ${pageNumber === noOfPages - 1 ? "disabled" : ""}`}
      >
        <span
          role="menuitem"
          aria-label="Go to last page"
          aria-disabled={pageNumber === noOfPages - 1}
          className="page-link"
          onClick={() => {
            if (pageNumber < noOfPages - 1) setPageNumber(noOfPages - 1);
          }}
        >
          Last
        </span>
      </li>
    </ul>
  </div>
</div>

        </div>
      </div>
    </div>
  );
}
