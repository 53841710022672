import React from 'react'
import InstantWorli from "../../../../assets/Sample/Instant-Worli.png"

export default function Warli() {
  return (
<div className="tab-content">
  <div className="tab-pane active" id="all-casino">
    <div className="casino-list">
      {/* <div className="casino-list-item">
        <a href="/casino/worli">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/worli.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
            }}
          />
        </a>
      </div> */}
      <div className="casino-list-item">
        <a href="/casino/worli">
          <div
            className="casino-list-item-banner"
            style={{
              backgroundImage:
                `url(${InstantWorli}), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")`,
            }}
          />
        </a>
      </div>
    </div>
  </div>
</div>

  )
}
