import React from 'react'
import Bacart from "../../../../assets/Sample/Baccarat.png"
import BacartTwo from "../../../../assets/Sample/Baccarat-2.png"

export default function Baccarats() {
  return (
    <div className="tab-content">
    <div className="tab-pane active" id="all-casino">
      <div className="casino-list">
        <div className="casino-list-item">
          <a href="/casino/baccarat">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  `url(${Bacart}), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")`,
              }}
            />
          </a>
        </div>
        <div className="casino-list-item">
          <a href="/casino/baccarat2">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  `url(${BacartTwo}), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")`,
              }}
            />
          </a>
        </div>
        {/* <div className="casino-list-item">
          <a href="/casino/teensin">
            <div
              className="casino-list-item-banner"
              style={{
                backgroundImage:
                  'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teensin.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")'
              }}
            />
          </a>
        </div> */}
      </div>
    </div>
  </div>
  
  )
}
