import React from "react";

export default function TeenPattiOneDayRules() {
  return (
    <div className="modal-body">
      <div>
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n    .rules-section .row.row5 {\n        margin-left: -5px;\n        margin-right: -5px;\n    }\n\n    .rules-section .pl-2 {\n        padding-left: .5rem !important;\n    }\n\n    .rules-section .pr-2 {\n        padding-right: .5rem !important;\n    }\n\n    .rules-section .row.row5>[class*="col-"],\n    .rules-section .row.row5>[class*="col"] {\n        padding-left: 5px;\n        padding-right: 5px;\n    }\n\n    .rules-section {\n        text-align: left;\n        margin-bottom: 10px;\n    }\n\n    .rules-section .table {\n        color: #fff;\n        border: 1px solid #444;\n        background-color: #222;\n        font-size: 12px;\n    }\n\n    .rules-section .table td,\n    .rules-section .table th {\n        border-bottom: 1px solid #444;\n    }\n\n    .rules-section ul li,\n    .rules-section p {\n        margin-bottom: 5px;\n    }\n\n    .rules-section::-webkit-scrollbar {\n        width: 8px;\n    }\n\n    .rules-section::-webkit-scrollbar-track {\n        background: #666666;\n    }\n\n    .rules-section::-webkit-scrollbar-thumb {\n        background-color: #333333;\n    }\n\n    .rules-section .rules-highlight {\n        color: #FDCF13;\n        font-size: 16px;\n    }\n\n    .rules-section .rules-sub-highlight {\n        color: #FDCF13;\n        font-size: 14px;\n    }\n\n    .rules-section .list-style,\n    .rules-section .list-style li {\n        list-style: disc;\n    }\n\n    .rules-section .rule-card {\n        height: 20px;\n        margin-left: 5px;\n    }\n\n    .rules-section .card-character {\n        font-family: Card Characters;\n    }\n\n    .rules-section .red-card {\n        color: red;\n    }\n\n    .rules-section .black-card {\n        color: black;\n    }\n\n    .rules-section .cards-box {\n        background: #fff;\n        padding: 6px;\n        display: inline-block;\n        color: #000;\n        min-width: 150px;\n    }\n.rules-section img {\n  max-width: 100%;\n}\n    ',
          }}
        />
        <div className="rules-section">
          <ul className="pl-4 pr-4 list-style">
            <li>Teenpatti is an indian origin three cards game.</li>
            <li>
              This game is played with a regular 52 cards deck between Player
              A and Player B .
            </li>
            <li>
              The objective of the game is to make the best three cards hand
              as per the hand rankings and win.
            </li>
            <li>
              You have a betting option of Back and Lay for the main bet.
            </li>
            <li>Rankings of the card hands from highest to lowest :</li>
            <li>1. Straight Flush (pure Sequence )</li>
            <li>2. Trail (Three of a Kind )</li>
            <li>3. Straight (Sequence)</li>
            <li>4. Flush (Color )</li>
            <li>5. Pair (Two of a kind )</li>
            <li>6. High Card </li>
          </ul>
          <div>
            <img
              src="https://sitethemedata.com/v3/static/front/img/casino-rules/teen6.jpg"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      <div>
        <div className="rules-section">
          <div>
            <h6 className="rules-highlight">Side bets :</h6>
          </div>
          <ul className="pl-4 pr-4 list-style">
            <li>
              <b>CONSECUTIVE CARDS:</b> It is a bet of having two or more
              consecutive cards in the game.
            </li>
            <li>eg: 2,3,5 10,3,9 Q,5,K 6,7,8 A,K,7</li>
            <li>
              For both the players Back and Lay odds are available, you can
              bet on either or both the players.
            </li>
            <li>
              <b>Odd - Even :</b> Here you can bet on every card whether it
              will be an odd card or an even card.
            </li>
            <li>
              <b>ODD CARDS :</b> A,3,5,7,9,J,K
            </li>
            <li>
              <b>EVEN CARDS:</b> 2,4,6,8,10,Q
            </li>
            <li>
              <b>NOTE:</b> In case of a Tie between the player A and player B
              bets placed on player A and player B (Main bets ) will be
              returned. (Pushed)
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
