import React, { useCallback, useRef } from "react";
import {
  getButtonValuesAPI,
  getLiveStreamingUrlAPI,
} from "../../../service/AuthService";
import { useState } from "react";
import { useEffect } from "react";
import {
  getMyBetsSoccerAPI,
  getMyBetsTennisAPI,
} from "../../../service/soccer&tennis";
import { getMyBetsCricketAPI } from "../../../service/cricket";

export default function GameSidebar({
  betPayload,
  gameDetails,
  setBetPayload,
  handleAmountChange,
  betHandler,
  id,
  game,
  gameEvent,
  gameOdds,
  type = "sport",
}) {
  const [buttons, setButtons] = useState([]);
  const [userBets, setUserBets] = useState([]);
  const [loading, setLoading] = useState(false);
  const modalRef = useRef();

  const getButtonValues = async () => {
    const { response, code } = await getButtonValuesAPI();
    if (code == 200) {
      const convertedData = {
        casino: [],
        sport: [],
      };
      for (const key in response) {
        if (key.startsWith("buttonValueCasino")) {
          const index = key.replace("buttonValueCasino", "");
          const label = response["buttonNameCasino" + index];
          const value = response[key];
          convertedData.casino.push({
            label,
            value,
          });
        } else if (key.startsWith("buttonValue")) {
          const index = key.replace("buttonValue", "");
          const label = response["buttonName" + index];
          const value = response[key];
          convertedData.sport.push({
            label,
            value,
          });
        }
      }
      localStorage.setItem("buttonValues", JSON.stringify(convertedData));
      setButtons(convertedData);
    }
  };
  const fetchMyBets = useCallback(async () => {
    if (game == "cricket") {
      const { response, code } = await getMyBetsCricketAPI(id);
      setUserBets(code == 200 ? response : []);
    } else if (game == "soccer") {
      const { response, code } = await getMyBetsSoccerAPI(id);
      setUserBets(code == 200 ? response : []);
    } else if (game == "tennis") {
      const { response, code } = await getMyBetsTennisAPI(id);
      setUserBets(code == 200 ? response : []);
    } else {
      setUserBets([]);
    }
  }, [id, type]);
  useEffect(() => {
    if (localStorage.getItem("buttonValues")) {
      setButtons(JSON.parse(localStorage.getItem("buttonValues")));
    } else {
      getButtonValues();
    }

    let interval = setInterval(() => {
      fetchMyBets();
    }, 2500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const betPlaceDelayed = () => {
    let delay = 1000;
    if (betPayload?.marketName?.startsWith("MATCH")) {
      delay = 3000;
    }
    setLoading(true);
    setTimeout(() => {
      betHandler();
      setLoading(false);
    }, delay);
  };
  const [channelId, setChannelId] = useState();
  const [isTvOpen, setIsTvOpen] = useState(true);
  const getLiveStreamingUrl = async () => {
    const { response } = await getLiveStreamingUrlAPI(gameEvent?.eventId);
    setChannelId(response);
  };
  useEffect(() => {
    getLiveStreamingUrl();
  }, [gameEvent?.eventId]);
  useEffect(() => {
    if (betPayload) {
      modalRef?.current?.addEventListener("click", (e) => {
        if (e.target === modalRef?.current) {
          setBetPayload(null);
        }
      });
    }
  }, [betPayload]);
  return (
    <>
      {window.innerWidth < 768 && betPayload && (
        <div
          role="dialog"
          aria-modal="true"
          ref={modalRef}
          className="fade modal show"
          tabIndex={-1}
          style={{ display: "block" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title h4">Place Bet</div>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setBetPayload(null)}
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="place-bet-modal back">
                  <div className="row align-items-end">
                    <div className="col-6">
                      <b>{betPayload?.runnerName}</b>
                    </div>
                    <div className="col-6">
                      <div className="float-end">
                        <button className="stakeactionminus btn">
                          <span className="fa fa-minus" />
                        </button>
                        <input
                          type="text"
                          className="stakeinput"
                          defaultValue={betPayload?.odd}
                          disabled={loading}
                          readOnly
                        />
                        <button className="stakeactionminus btn">
                          <span className="fa fa-plus" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-4">
                      <input
                        type="number"
                        value={betPayload?.amount}
                        disabled={loading}
                        className="stakeinput w-100"
                        defaultValue=""
                        onChange={(e) => handleAmountChange(e.target.value)}
                      />
                    </div>
                    <div className="col-4 d-grid">
                      <button
                        onClick={betPlaceDelayed}
                        className="btn btn-primary btn-block"
                        disabled={loading}
                      >
                        {loading ? (
                          <i className="fa fa-spinner fa-spin" />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                    <div className="col-4 text-center pt-2">
                      <span>0</span>
                    </div>
                  </div>
                  <div className="place-bet-buttons mt-2">
                    {buttons[type]?.map((button) => (
                      <button
                        disabled={loading}
                        className="btn btn-place-bet"
                        onClick={() => handleAmountChange(button.value)}
                      >
                        {button.label}
                      </button>
                    ))}
                  </div>
                  <div className="mt-3 d-flex justify-content-between align-items-center">
                    <b>
                      <span>
                        Range: {betPayload?.minStack} to {betPayload?.maxStack}
                      </span>
                    </b>
                    <button className="btn btn-info">Edit</button>
                  </div>

                  {game == "cricket" &&
                    betPayload?.runnersAndExposure?.map((runner) => (
                      <div className="row mt-2">
                        <div className="col-4">
                          <span>{runner?.runnerName}</span>
                        </div>
                        <div
                          style={{
                            color: runner?.exposure > 0 ? "green" : "red",
                          }}
                          className="col-4 text-center"
                        >
                          <span>{runner?.exposure}</span>
                        </div>
                        <div
                          style={{
                            color: runner?.preExposure > 0 ? "green" : "red",
                          }}
                          className="col-4 text-end"
                        >
                          <span>{runner?.preExposure}</span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="sidebar right-sidebar">
        <a className="bet-nation-game-name blink-message" href="/casino/dt20">
          <i className="fas fa-info-circle me-1" />
          <div>Dragon Tiger 20</div>
        </a>
        <div className="sidebar-box">
          <div
            onClick={() => {
              setIsTvOpen(!isTvOpen);
            }}
            className="sidebar-title"
          >
            <h4>Live Match</h4>
          </div>
          {isTvOpen && (
            <iframe
              style={{
                width: "100%",
                height: "230px",
              }}
              src={`https://video.proexch.in/tv/static?chid=${channelId}`}
            />
          )}
        </div>

        {betPayload && (
          <div className="sidebar-box place-bet-container">
            <div className="sidebar-title">
              <h4>Place Bet</h4>
            </div>
            <div className={`place-bet-box ${betPayload?.type}`}>
              <div className="place-bet-box-header">
                <div className="place-bet-for">(Bet for)</div>
                <div className="place-bet-odds">Odds</div>
                <div className="place-bet-stake">Stake</div>
                <div className="place-bet-profit">Profit</div>
              </div>
              <div className="place-bet-box-body">
                <div className="place-bet-for">
                  <span>{betPayload?.runnerName}</span>
                </div>
                <div className="place-bet-odds">
                  <input
                    type="text"
                    className="form-control"
                    disabled={loading}
                    value={betPayload?.odd}
                    defaultValue={betPayload?.odd}
                  />
                  <div className="spinner-buttons input-group-btn btn-group-vertical">
                    <button className="btn-default">
                      <i className="fa fa-angle-up" />
                    </button>
                    <button className="btn-default">
                      <i className="fa fa-angle-down" />
                    </button>
                  </div>
                </div>
                <div className="place-bet-stake">
                  <input
                    type="number"
                    className="form-control"
                    disabled={loading}
                    defaultValue={betPayload?.amount}
                    value={betPayload?.amount}
                    onChange={(e) => handleAmountChange(e.target.value)}
                  />
                </div>
                <div className="place-bet-profit">
                  {parseFloat(betPayload?.profit).toFixed(2)}
                </div>
              </div>
              <div className="place-bet-buttons">
                {buttons[type]?.map((button) => (
                  <button
                    disabled={loading}
                    className="btn btn-place-bet"
                    onClick={() => handleAmountChange(button.value)}
                  >
                    {button.label}
                  </button>
                ))}
              </div>
              <div className="place-bet-action-buttons">
                <div>
                  <button disabled={loading} className="btn btn-info">
                    Edit
                  </button>
                </div>
                <div>
                  <button
                    disabled={loading}
                    onClick={() => setBetPayload(null)}
                    className="btn btn-danger me-1"
                  >
                    Reset
                  </button>
                  <button
                    onClick={betPlaceDelayed}
                    className="btn btn-success"
                    disabled={loading}
                  >
                    {loading ? (
                      <i className="fa fa-spinner fa-spin" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="sidebar-box my-bet-container">
          <div className="sidebar-title">
            <h4>My Bet</h4>
          </div>
          <div className="my-bets">
            <div className="table-responsive w-100">
              <table className="table">
                <thead>
                  <tr>
                    <th>Matched Bet</th>
                    <th className="text-end">Odds</th>
                    <th className="text-end">Stake</th>
                  </tr>
                </thead>
                <tbody>
                  {userBets?.map((bet) => (
                    <tr className={bet?.back ? "back" : "lay"}>
                      <td>
                        <span className="bet-name">{bet?.runnerName}</span>
                        <span className="bet-type">{bet?.type}</span>
                      </td>
                      <td className="text-end">
                        <span className="bet-odd">
                          {bet?.back ? bet?.back : bet?.lay}
                        </span>
                      </td>
                      <td className="text-end">
                        <span className="bet-stake">{bet?.amount}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
