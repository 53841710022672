import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter,
  Navigate,
  Route,
  Router,
  Routes,
  useLocation,
} from "react-router-dom";
import ActivityLog from "./pages/activityLog/ActivityLog";
import BetHistory from "./pages/betHistory/BetHistory";
import CasinoResult from "./pages/casinoResults/CasinoResults";
import Footer from "./includes/footer";
import ProfitLossReport from "./pages/profitLossReport/ProfitLossReport";
import UnSetteledBet from "./pages/unSetteledBet/UnSetteledBet";
import store, { saveState } from "./redux/store";
import { useState } from "react";

import PrivateOutletM from "./layout/PrivateOutlet";
import AccountStatementM from "./pages/accountStatement/AccountStatementM";
import PublicOutletM from "./layout/PublicOutletM";
import ChangeButtonValue from "./pages/changeButtonValue";
import ChangePassword from "./pages/ChangePassword";
import SuperOver from "./pages/casino/SuperOver";
import Race20 from "./pages/casino/Race20";
import FiveCricket from "./pages/casino/FiveCricket";
import AndarBahar2 from "./pages/casino/AndarBahar2";
import DragonTiger2 from "./pages/casino/DragonTiger2";
import Baccarat2 from "./pages/casino/Baccarat2";
import Baccarat from "./pages/casino/Baccarat";
import Lucky7A from "./pages/casino/Lucky7A";
import Lucky7B from "./pages/casino/Lucky7B";
import AmarAkhbarAnthoany from "./pages/casino/AmarAkhbarAnthoany";
import TeenpattiOneDay from "./pages/casino/TeenpattiOneDay";
import { QueryClient, QueryClientProvider } from "react-query";
import DragonTiger from "./pages/casino/DragonTiger";
import InitialRule from "./components/InitialRule";
import SecurityAuth from "./pages/securityAuth/SecurityAuth";
import Otp from "./layout/Otp";
import { Toaster } from "react-hot-toast";
import ResponsibleGaming from "./pages/ResponsibleGaming";
import TermsAndCondition from "./pages/TermsAndCondition";
import Home from "./pages/home";
import CricketGame from "./pages/game/cricket/CricketGames";
import Header from "./includes/header";
import SoccerGame from "./pages/game/soccer/SoccerGame";
import TennisGame from "./pages/game/tennis/TennisGame";
import BallByBall from "./pages/casino/BallByBall";
import CasinoWar from "./pages/casino/CasinoWar";
import DragonTigerLion from "./pages/casino/DragonTigerLion";
import TeenPattiOpen from "./pages/casino/TeenPattiOpen";
import TeenPatti from "./pages/casino/TeenPatti";
import Card32A from "./pages/casino/Card32A";
import TeenPattiTest from "./pages/casino/TeenPattiTest";
import WorliMatka from "./pages/casino/WorliMatka";
import BTable from "./pages/casino/BTable";
import Poker1Day from "./pages/casino/Poker1Day";
import Poker20 from "./pages/casino/Poker20";
import AndarBahar from "./pages/casino/AndarBahar";
import Card32B from "./pages/casino/Card32B";
import DragonTigerOneDay from "./pages/casino/DragonTigerOneDay";
import CasinoMeter from "./pages/casino/CasinoMeter";
import Poker6Player from "./pages/casino/Poker6Player";
import CasinoQueen from "./pages/casino/CasinoQueen";
import CricketMatch20 from "./pages/casino/CricketMatch20";
import CasinoComingSoon from "./pages/casino/CasinoComingSoon";
import CasinoList from "./pages/casino/CasinoList";
import Roulet from "./pages/casino/components/casinoGroup/Roulet";
import SportsPage from "./pages/home/SportsPage";
import InternationalCasino from "./pages/casino/InternationalCasino";
import VideoIframe from "./layout/VideoIframe";
import SportIframe from "./layout/SportIframe";
import InternationalReport from "./pages/internationalReport/InternationalReport";
export default function App() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.account.userData);
  const isLoading = useSelector((state) => state.account.loading);
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [queryClient] = useState(() => new QueryClient());
  useEffect(() => {
    store.subscribe(() => {
      saveState(store.getState());
    });
  }, [0]);

  return (
    <QueryClientProvider client={queryClient}>
      <Toaster />
      {userData?.token && userData?.resetRequired ? (
        <PrivateOutletM>
          <Routes>
            <Route path="/" element={<ChangePassword />} />
          </Routes>
        </PrivateOutletM>
      ) : userData.token ? (
        <>
          {(
            !window?.location?.pathname.includes("/terms-and-conditions") ||
            !window?.location?.pathname.includes("/responsible-gaming")) && (
            <Header />
          )}
          <Routes>
            {" "}
            <Route
              exact
              path="/terms-and-conditions"
              element={<TermsAndCondition />}
            />
          </Routes>
          <PrivateOutletM>
            <InitialRule />

            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/home" element={<Home />} />
              <Route exact path="/casino-page" element={<CasinoList />} />
              <Route exact path="/video-iframe" element={<VideoIframe />} />

              <Route exact path="/video-iframe/:id" element={<VideoIframe />} />

              <Route exact path="/sport-iframe" element={<SportIframe />} />
              <Route exact path="/sport-iframe/:id" element={<SportIframe />} />

              <Route
                exact
                path="/international"
                element={<InternationalCasino />}
              />
              <Route
                exact
                path="/international/:id"
                element={<InternationalCasino />}
              />
              <Route exact path="/casino-page/:id" element={<CasinoList />} />
              <Route exact path="/sports" element={<Home />} />
              <Route
                exact
                path="/all-sports/:sportId"
                element={<SportsPage />}
              />
              <Route
                exact
                path="/responsible-gaming"
                element={<ResponsibleGaming />}
              />
              <Route exact path="/game/soccer/:id" element={<SoccerGame />} />
              <Route exact path="/game/cricket/:id" element={<CricketGame />} />
              <Route exact path="/game/tennis/:id" element={<TennisGame />} />

              <Route
                path="/reports/accountstatement"
                element={<AccountStatementM />}
              />
              <Route path="/reports/bethistory" element={<BetHistory />} />
              <Route
                path="/reports/profitloss"
                element={<ProfitLossReport />}
              />
              <Route
                path="/reports/unsetteledbet"
                element={<UnSetteledBet />}
              />
              <Route
                exact
                path="/terms-and-conditions"
                element={<TermsAndCondition />}
              />
              <Route path="/reports/activity-log" element={<ActivityLog />} />
              <Route path="/reports/casinoresults" element={<CasinoResult />} />
              <Route
                path="/reports/internationlreport"
                element={<InternationalReport />}
              />
              <Route
                path="/setting/changebtnvalue"
                element={<ChangeButtonValue />}
              />
              <Route
                path="/settings/security-auth"
                element={<SecurityAuth />}
              />
              <Route
                path="/setting/changepassword"
                element={<ChangePassword />}
              />
              <Route path="/casino/ballbyball" element={<BallByBall />} />
              <Route path="/casino/superover" element={<SuperOver />} />
              <Route path="/casino/race20" element={<Race20 />} />
              <Route path="/casino/teen8" element={<TeenPattiOpen />} />
              <Route path="/casino/teen20" element={<TeenPatti />} />
              <Route path="/casino/worli" element={<WorliMatka />} />
              <Route path="/casino/cricketv3" element={<FiveCricket />} />
              <Route path="/casino/abj" element={<AndarBahar2 />} />
              <Route path="/casino/card32eu" element={<Card32B />} />
              <Route path="/casino/ab20" element={<AndarBahar />} />
              <Route path="/casino/btable" element={<BTable />} />
              <Route path="/casino/poker" element={<Poker1Day />} />
              <Route path="/casino/poker20" element={<Poker20 />} />
              <Route path="/casino/teen" element={<TeenpattiOneDay />} />
              <Route path="/casino/dt6" element={<DragonTigerOneDay />} />
              <Route path="/casino/dtl20" element={<DragonTigerLion />} />
              <Route path="/casino/teen9" element={<TeenPattiTest />} />
              <Route path="/casino/dt202" element={<DragonTiger2 />} />
              <Route path="/casino/card32" element={<Card32A />} />
              <Route path="/casino/dt20" element={<DragonTiger />} />
              <Route path="/casino/baccarat2" element={<Baccarat2 />} />
              <Route path="/casino/baccarat" element={<Baccarat />} />
              <Route path="/casino/war" element={<CasinoWar />} />
              <Route path="/casino/lucky7eu" element={<Lucky7B />} />
              <Route path="/casino/lucky7" element={<Lucky7A />} />
              <Route path="/casino/poker6" element={<Poker6Player />} />
              <Route path="/casino/queen" element={<CasinoQueen />} />
              <Route path="/casino/cmatch" element={<CricketMatch20 />} />

              <Route path="/casino/aaa" element={<AmarAkhbarAnthoany />} />
              <Route path="/casino/cmeter" element={<CasinoMeter />} />
              <Route
                path="/casino/teenpatti/oneday"
                element={<TeenpattiOneDay />}
              />
              {/* REdirect to coming soon */}
              <Route path="/casino-list/*" element={<CasinoComingSoon />} />
              <Route path="/casino/*" element={<CasinoComingSoon />} />
            </Routes>

            {!window?.location?.pathname.includes("/video-iframe") &&
        (!window?.location?.pathname.includes("/sport-iframe")  && (
                <Footer />
              ))}
          </PrivateOutletM>
        </>
      ) : (
        <Routes>
          <Route path="/" element={<PublicOutletM />} />
          <Route path="/otp" element={<Otp />} />
          <Route
            exact
            path="/responsible-gaming"
            element={<ResponsibleGaming />}
          />
          <Route exact path="/video-iframe" element={<VideoIframe />} />
          <Route exact path="/video-iframe/:id" element={<VideoIframe />} />
          <Route exact path="/sport-iframe" element={<SportIframe />} />
          <Route exact path="/sport-iframe/:id" element={<SportIframe />} />

          <Route
            exact
            path="/terms-and-conditions"
            element={<TermsAndCondition />}
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      )}
    </QueryClientProvider>
  );
}
