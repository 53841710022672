import React from "react";
import { useEffect } from "react";

import {
  bgColor,
  buttonColors,
  getCurrentDateTimeLocal,
  searchSubstringInArrayObjects,
} from "../../utils/constants";
import {
  casinoReportHistoryAPI,
  getIndividualResultCasinoAPI,
} from "../../service/casino";
import moment from "moment";
import GenericIndividualResult from "../../pages/casino/components/GenericIndividualResult";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

export default function CasinoResult() {
  const [from, setFrom] = React.useState(getCurrentDateTimeLocal());
  const [sport, setSport] = React.useState("");
  const [individualResult, setIndividualResult] = React.useState(null);
  const { id, shortName } = useParams();
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(10);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [selectedMarket, setSelectedMarket] = React.useState({});
  const [marketData, setMarketData] = React.useState({});

  const [isCasinoSelected, setIsCasinoSelected] = React.useState(false);
  console.log("00=>", id, shortName);

  const getCasinoResult = async () => {
    if (!sport) {
      toast.error("Please select a casino type!"); // Trigger toast if sport is empty
      return;
    }
  
    const { response } = await casinoReportHistoryAPI({
      casinoGames: id ? id?.toUpperCase() : sport?.toUpperCase(),
      date: new Date(from).toISOString(),
      page: page,
      size: size,
    });
    setIsCasinoSelected(true);
   
    setData(response.content);
    setSport(sport)
  };

  const search = async (e) => {
    if (e.target.value == "") return getCasinoResult();

    setData(
      searchSubstringInArrayObjects(
        data,
        ["round_id", "winner"],
        e.target.value
      )
    );
  };
  const getIndividualResult = async (marketId) => {
    const { response } = await getIndividualResultCasinoAPI(
      marketId,
      sport?.toUpperCase()
    );
    setIndividualResult(response);
    
  };

  useEffect(() => {
    if (isCasinoSelected) {
      getCasinoResult();
    }
   
  }, [size, page]);

  const handleRowClick = (item) => {
    setIndividualResult(item?.marketId);
    getIndividualResult(item?.marketId);
  };

  return (
    <div className="center-main-container report-page">
      <div className="center-container">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Casino Results</h4>
          </div>
          {individualResult && (
            <>
              <div className="modal-backdrop fade show" />
              <GenericIndividualResult
                data={individualResult}
                shortName={sport}
                GAME_ID={sport?.toUpperCase()}
                onClose={() => setIndividualResult(null)}
              />
            </>
          )}
          <div className="card-body">
            <div className="report-form">
              <div className="row row5">
                <div className="col-lg-2 col-md-3 col-5">
                  <div className="mb-2 custom-datepicker">
                    <input
                      type="date"
                      className="form-control"
                      value={moment(from).format("YYYY-MM-DD")}
                      onChange={(e) => setFrom(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-2 col-md-3 col-7">
                  <div className="mb-2 input-group position-relative">
                    <select
                      onChange={(e) => {
                        setSport(e.target.value);
                      }}
                      value={sport?.toLowerCase()}
                      className="form-select"
                      name="type"
                    >
                      <option value="" defaultValue={""} disabled>
                        Select Casino Type
                      </option>
                      <option value="aaa">Amar Akabar Anthony</option>
                      <option value="ab_20">Ander Bahr 1</option>
                      <option value="abj">Andar bahar 2</option>
                      <option value="baccarat">Baccarat1</option>
                      <option value="baccarat2">Baccarat2</option>
                      <option value="bollywood_table">BollyWood Table</option>
                      <option value="card_32">32card A</option>
                      <option value="card32eu">32 Card B</option>
                      <option value="casino_meter">Casino Meter</option>
                      <option value="casino_queen">Casino Queen</option>
                      <option value="casino_war">Casino War</option>
                      <option value="cricket_match_20">20-20 Cricket</option>
                      <option value="cricket_v3">Five Cricket</option>
                      <option value="dragon_tiger_20">
                        Dragon Tiger 20-20
                      </option>
                      <option value="dragon_tiger_20_2">
                        Dragon Tiger 2 20-20
                      </option>
                      <option value="dragon_tiger_6">Dragon Tiger 1 Day</option>
                      <option value="lucky7">Lucky7 A</option>
                      <option value="lucky7eu">Lucky7 B</option>
                      <option value="poker_1_day">Poker 1 Day</option>
                      <option value="poker_20">Poker 20-20</option>
                      <option value="race20">Race 20-20</option>
                      <option value="superover">Super Over</option>
                      <option value="teen">Teen Patti 1 Day</option>
                      <option value="teen_20">Teen Patti, T20 20</option>
                      <option value="teen_8">Open Teen Patti</option>
                      <option value="teen_9">Teen Patti Test</option>
                      <option value="worli2">Instant Worli 2</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 d-grid">
                  <button
                    onClick={getCasinoResult}
                    type="submit"
                    className="btn btn-primary btn-block"
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="row row10 mt-2 justify-content-between">
                <div className="col-lg-2 col-6">
                  <div className="mb-2 input-group position-relative">
                    <span className="me-2">Show</span>
                    <select
                      onChange={(e) => {
                        setSize(e.target.value);
                      }}
                      className="form-select"
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                    </select>
                    <span className="ms-2">Entries</span>
                  </div>
                </div>
                <div className="col-lg-2 col-6">
                  <div className="mb-2 input-group position-relative">
                    <span className="me-2">Search:</span>
                    <input
                      type="search"
                      className="form-control"
                      placeholder="0 records..."
                      defaultValue=""
                    />
                  </div>
                </div>
              </div>
              <div className="mt-2 table-responsive">
                <table
                  role="table"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr role="row">
                      <th colSpan={1} role="columnheader" className="round-id">
                        Round ID
                      </th>
                      <th colSpan={1} role="columnheader">
                        Winner
                      </th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    {data?.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          role="row"
                          onClick={() => handleRowClick(item)}
                          style={{ cursor: "pointer" }}
                        >
                          <td role="cell" className="round-id">
                            {item.marketId}
                          </td>
                          <td role="cell">{item.winner}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    {/* Pagination */}
                    <tr>
                      <td colSpan={2}>
                        <div className="d-flex justify-content-between">
                          <div>
                            <button
                              onClick={() => setPage(page - 1)}
                              className="btn btn-primary"
                              disabled={page == 0}
                            >
                              Previous
                            </button>
                          </div>
                          <div>
                            <button
                              onClick={() => setPage(page + 1)}
                              className="btn btn-primary"
                              disabled={data.length < size}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalOpen && (
        <GenericIndividualResult
          data={data}
          shortName={sport}
          GAME_ID={sport?.toUpperCase()}
          onClose={() => setModalOpen(false)}
        />
      )}
    </div>
  );
}
